<template>
  <section class="panel-section">
    <div class="back-btn" @click="back">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <el-form
      :model="formModel"
      label-position="left"
      status-icon
      :rules="rules"
      ref="formModel"
      label-width="140px"
      class="form-model"
    >
      <div class="title">{{ title }}</div>
      <el-form-item label="工作台账号">
        <el-input
          type="input"
          v-model="formModel.workbenchAccount"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="认证身份类别">
        <el-select
          class="com-width"
          v-model="formModel.workbenchUserRole"
          disabled
        >
          <el-option
            v-for="(item, i) in indentifyTypeConditionList.slice(1)"
            :key="i"
            :label="item.label"
            :value="Number(item.value)"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="title">{{ enterpriseTitle }}</div>
      <el-form-item label="姓名">
        <el-input
          type="input"
          v-model="formModel.realName"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="证件类型">
        <el-select class="com-width" v-model="formModel.idCardType" disabled>
          <el-option label="居民身份证" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件编号">
        <el-input
          type="input"
          v-model="formModel.idCardNo"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <div class="title">企业基本信息</div>
      <el-form-item label="企业名称">
        <el-input
          type="input"
          v-model="formModel.name"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码">
        <el-input
          type="input"
          v-model="formModel.creditCode"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="营业执照">
        <el-image
          :src="formModel.businessLicenseUrl"
          :preview-src-list="[formModel.businessLicenseUrl]"
          class="cer-img"
        >
          <div slot="error">
            {{ $CommonMsg.imgErr }}
          </div>
        </el-image>
      </el-form-item>

      <template v-if="isAgent">
        <el-form-item label="企业法定代表人姓名">
          <el-input
            type="input"
            v-model="formModel.corporation"
            class="com-width"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="企业代理授权书">
          <el-image
            :src="formModel.powerOfAttorneyUrl"
            :preview-src-list="[formModel.powerOfAttorneyUrl]"
            class="cer-img"
          >
            <div slot="error">
              {{ $CommonMsg.imgErr }}
            </div>
          </el-image>
        </el-form-item>
      </template>

      <div class="title">企业联系人信息</div>
      <el-form-item label="联系人姓名">
        <el-input
          type="input"
          v-model="formModel.contact"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人手机">
        <el-input
          type="input"
          v-model="formModel.phone"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人邮箱">
        <el-input
          type="input"
          v-model="formModel.email"
          class="com-width"
          disabled
        ></el-input>
      </el-form-item>
      <div class="title">云盾审核信息</div>
      <el-form-item label="认证提交时间">
        <el-date-picker
          v-model="formModel.auditCommitTime"
          type="datetime"
          class="com-width"
          disabled
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="云盾审核时间">
        <el-date-picker
          v-model="formModel.auditTime"
          type="datetime"
          class="com-width"
          disabled
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="云盾审核结果">
        <el-select class="com-width" v-model="formModel.auditStatus" disabled>
          <el-option
            v-for="(item, i) in auditConditionList.slice(1)"
            :key="i"
            :label="item.label"
            :value="Number(item.value)"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item v-if="isAuth == 1">
        <el-button class="el-btn" type="primary" @click="auditFunc(1)"
          >企业认证通过</el-button
        >
        <el-button class="el-btn" @click="auditFunc(0)"
          >企业认证不通过</el-button
        >
      </el-form-item> -->
    </el-form>

    <div v-if="isAuth == 1" class="btn-wrap">
      <el-button class="el-btn" type="primary" @click="auditFunc(1)"
        >企业认证通过</el-button
      >
      <el-button class="el-btn" @click="auditFunc(0)"
        >企业认证不通过</el-button
      >
    </div>

  </section>
</template>
<script>
import {
  auditConditionList,
  indentifyTypeConditionList,
} from "@/util/constant.js";
import API_ACCOUNT from "@/api/api_account";
import dayjs from "dayjs";
export default {
  data() {
    return {
      indentifyTypeConditionList,
      auditConditionList,
      formModel: {
        workbenchAccount: undefined,
        workbenchUserRole: undefined,
        realName: undefined,
        idCardType: "1",
        idCardNo: undefined,
        name: undefined,
        creditCode: undefined,
        businessLicenseUrl: "",
        corporation: undefined,
        powerOfAttorneyUrl: "",
        contact: undefined,
        phone: undefined,
        email: undefined,
        auditCommitTime: undefined,
        auditTime: undefined,
        auditStatus: undefined,
      },
      rules: {},
      id: undefined,
      isAuth: false,
    };
  },
  computed: {
    title() {
      return this.isAuth ? "企业代理人认证 人工审核" : "工作台账号企业认证信息";
    },
    enterpriseTitle() {
      return this.isAgent ? "代理人实名信息" : "企业法定代表人信息";
    },
    isAgent() {
      return this.formModel.workbenchUserRole == 1;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    resetForm() {
      this.formModel = {
        workbenchAccount: undefined,
        workbenchUserRole: undefined,
        realName: undefined,
        idCardType: "1",
        idCardNo: undefined,
        name: undefined,
        creditCode: undefined,
        businessLicenseUrl: "",
        corporation: undefined,
        powerOfAttorneyUrl: "",
        contact: undefined,
        phone: undefined,
        email: undefined,
        auditCommitTime: undefined,
        auditTime: undefined,
        auditStatus: undefined,
      };
    },
    auditFunc(type) {
      this.$confirm(`确定认证${type == 1 ? "" : "不"}通过吗？`, "提示", {
        type: "warning",
      }).then(() => {
        API_ACCOUNT.enterpriseAuth({
          shopId: this.id,
          success: Boolean(type),
        }).then(({ data }) => {
          if (data.code == 200) {
            this.$message({
              message: `认证${type == 1 ? "" : "不"}通过~`,
              type: type == 1 ? "success" : "error",
            });
            this.back();
          } else {
            this.$message.error({
              message: data.msg || this.$CommonMsg["networkErr"],
            });
          }
        });
      });
    },
    getEnterpriseAuthInfo() {
      API_ACCOUNT.enterpriseAuthInfo(this.id).then((res = {}) => {
        const { data: { data = {}, code, msg = "" } = {} } = res;
        if (code == 200 && data) {
          Object.assign(this.formModel, data);
          if(this.isAgent && data.auditStatus > 3) {
            this.formModel.auditStatus = 2;
          }
        } else {
          this.resetForm();
        }
      });
    },
  },
  activated() {
    this.id = this.$route.query.id;
    this.isAuth = this.$route.query.isAuth;
    this.resetForm();
    if (this.id) {
      this.getEnterpriseAuthInfo();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";

.panel-section {
  padding: 40px 48px;
  .title {
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    margin: 24px 0;
  }
  .btn-wrap {
    position: fixed;
    bottom: 0;
    width: auto;
    right: 24px;
    left: 252px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 0 30px;
    background-color: #fff;
    padding-left: 188px;
  }
  .form-model {
    padding-bottom: 40px;
  }
}

.com-width {
  width: 400px !important;
  min-width: 400px !important;
}
/deep/ .el-alert {
  padding: 0 16px;
}
</style>